import React from 'react';
import { BrowserRouter as Router, Route, MemoryRouter, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

class BackButtonLocation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectLocation: ''
    }
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  changeHandler(e) {
    let history = e.target.dataset.history;

    setTimeout(function(){
      history = history.substring(1);

      let backLocation;

      if(history === 2) {
        backLocation = "/"
      }else {
        backLocation = "/" + (history - 1);
      }

      this.setState({
        redirect: true,
        redirectLocation: backLocation
      });
    }.bind(this), 1000);
    
  }

  render() {
    const { history } = this.props;
    const hideArray = ["/", "/2", "/3", "/4"];

    if(this.props.hideOn) {
      const hideOn = this.props.hideOn;
      hideOn.forEach(step => hideArray.push(step));
    }

    const showClass = hideArray.includes(history.location.pathname) ? "hide" : "show"
    return (
      <>
        <button className={`back-button ${showClass} router-back-button`} data-history={history.location.pathname} onClick={this.changeHandler.bind(this)}>
          <span className="text">Back</span>
          <div className="lines">
              <span className="line" />
              <span className="line" />
          </div>
        </button>

        {this.state.redirect &&
          <Redirect to={this.state.redirectLocation} />
        }
      </>
    );
  }
}

export default withRouter(BackButtonLocation);
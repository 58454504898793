import React from 'react';
import { BrowserRouter as Router, Route, MemoryRouter } from "react-router-dom";
import StepWrap from '../components/steps/StepRoute.js';
import BackButton from '../components/BackButton.js';

//STEPS
import Opening from '../components/steps/Opening.js';
import Closing from '../components/steps/Closing.js';
import Login from '../components/steps/Login.js';
import MissionControlCC from '../components/steps/MissionControlCC.js';
import AlertsView from '../components/steps/AlertsCC.js';
import DigitalReport from '../components/steps/DigitalReportCC.js';
import { ToolTip } from '../components/steps/ToolTip.js';

//IMAGES
import StaticImages from '../components/StaticImages.js';
import pdfCover from '../images/PDFReport-Cover.png';
import pdfPg5 from '../images/PDFReport-Interior-Pg5.png';
import pdfCompSummary from '../images/DTEX-UC2-Seq6c-PDF3.png';

export default class Steps extends React.Component {
	render() {
        
        return (
            <MemoryRouter>
                <div className="steps-wrap">                    
                    <StepWrap>
                        <BackButton />
                        <Route path="/">
                          <Opening />
                        </Route>
                        <Route path="/2">
                          <Login next="/3">
                          </Login>
                        </Route>
                        <Route path="/3">
                          <MissionControlCC />
                        </Route>
                        <Route path="/4">
                          <AlertsView />
                        </Route>
                        <Route path="/5">
                          <DigitalReport />
                        </Route>
                        
                        {/* REPORT */}
                        <Route path="/6">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfCover} />
                          <ToolTip top="69" left="1021" width="342" arrow="left" next="/7" tip="The report summarizes the  activities generated by a user, how many devices the user has been active on and the overall risk rating." delay={1}/>
                        </Route>
                        <Route path="/7">
                          <StaticImages className="pdf-report" backgroundColor="#363636" image1={pdfCover} />
                          <ToolTip top="324" left="1021" width="330" arrow="left" next="/8" tip="The spider plot provides a quick visual summary of how the user skews across Insider Threat behavioral categories.  In this case we see Roger skewing toward compromised behaviors." delay={.1}/>
                        </Route>
                        <Route path="/8">
                          <StaticImages className="pdf-report" backgroundColor="#363636" image1={pdfCover} />
                          <ToolTip top="606" left="1021" width="342" arrow="left" next="/9" tip="Each behavioral category is broken out by risk score and highlights the user activities that created the highest potential risk to the organization." delay={.1}/>
                        </Route>

                        <Route path="/9">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg5} />
                          <ToolTip top="452" left="1021" width="342" arrow="left" next="/10" tip="MITRE ATT&CK® Framework mapping highlights the attack techniques triggered by the user’s activities." delay={1}/>
                        </Route>

                        <Route path="/10">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfCompSummary} />
                          <ToolTip top="386" left="1021" width="342" next="/11" tip="Compromised Behavior Summary" delay={1}/>
                        </Route>

                        <Route path="/11">
                          <Closing />
                        </Route>

                    </StepWrap>
                </div>
            </MemoryRouter>
        );
    }              
}
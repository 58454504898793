import React from 'react';
import { BrowserRouter as Router, Route, MemoryRouter } from "react-router-dom";

import WOEDash from '../components/steps/WOEDash.js';
import ForensicInvestigation from '../components/steps/ForensicInv.js';
import Opening from '../components/steps/Opening.js';
import Login from '../components/steps/Login.js';
import Closing from '../components/steps/Closing.js';
import StepWrap from '../components/steps/StepRoute.js';
import BackButton from '../components/BackButton.js';
import {ToolTip} from '../components/steps/ToolTip.js';
import StaticImages from '../components/StaticImages.js';

import pdfPg1 from '../images/DTEX-UC3-Seq4-Report-PDF-Pg1.png';
import pdfPg2 from '../images/DTEX-UC3-Seq4-Report-PDF-Pg2.png';
import pdfPg3 from '../images/DTEX-UC3-Seq4-Report-PDF-Pg3.png';
import pdfPg7 from '../images/DTEX-UC3-Seq4-Report-PDF-Pg7.png';
import pdfPg8 from '../images/DTEX-UC3-Seq4-Report-PDF-Pg8.png';
import pdfPg9 from '../images/DTEX-UC3-Seq4-Report-PDF-Pg9.png';


export default class Steps extends React.Component {
    render() {

        return (
            <MemoryRouter>
                <div className="steps-wrap">                    
                    <StepWrap>
                        <BackButton hideOn={["/16"]}/>
                        <Route path="/">
                          <Opening pulse="true"/>
                        </Route>                        
                        <Route path="/2">
                          <Login username="Pulse_User" next="/3" welcome="Welcome, let's explore the PULSE dashboard. Click below to get started." />
                        </Route>
                        <Route path="/3">
                            <WOEDash />
                        </Route>
                        <Route path="/4">
                            <ForensicInvestigation />
                        </Route>

                        {/* REPORT */}

                        <Route path="/5">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg1} />
                          <ToolTip top="398" left="429" width="581" arrow="" next="/6" tip="You can easily download reports to review monthly trends." delay={1}/>
                        </Route>

                        <Route path="/6">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg1} noTransition={true} />
                          <ToolTip width="346" top="106" left="1030" arrow="left" next="/7" tip="Summary of workstations, servers, and active users across the organization " delay={1}/>
                        </Route>

                        <Route path="/7">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg1} noTransition={true} />
                          <ToolTip width="366" top="257" left="44" arrow="right" next="/8" tip="A summary of threats broken down by category along with details highlighting top risks" delay={1}/>
                        </Route>

                        <Route path="/8">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg1} noTransition={true} />
                          <ToolTip width="306" top="249" left="1040" arrow="left" next="/9" tip="Workforce analytic trends across organization​" delay={1}/>
                        </Route>

                        <Route path="/9">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg1} noTransition={true} />
                          <ToolTip width="306" top="425" left="1040" arrow="left" next="/10" tip="Workforce analytic trends broken down by region​" delay={1}/>
                        </Route>

                        <Route path="/10">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg1} noTransition={true} />
                          <ToolTip width="333" top="644" left="1040" arrow="left" next="/11" tip="Key application usage trends​" delay={1}/>
                        </Route>

                        <Route path="/11">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg2} />
                          <ToolTip width="363" top="447" left="1040" arrow="left" next="/12" tip="This report provides trends regarding operational efficiency, core business application usage, and remote working." delay={1}/>
                        </Route>

                        <Route path="/12">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg3} />
                          <ToolTip width="363" top="374" left="1040" arrow="left" next="/13" tip="Overview of the ‘off-network’ activity" delay={1}/>
                        </Route>

                        <Route path="/13">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg8} />
                          <ToolTip width="363" top="411" left="1040" arrow="left" next="/14" tip="General overview of the most utilized applications in the organization." delay={1}/>
                        </Route>

                        <Route path="/14">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg7} />
                          <ToolTip width="376" top="278" left="1040" arrow="left" next="/15" tip="Overview of specified Engineering Software activity within the organization" delay={1}/>
                        </Route>

                        <Route path="/15">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg9} />
                          <ToolTip width="376" top="411" left="1040" arrow="left" next="/16" tip="General overview of the most utilized web domains in the organization" delay={1}/>
                        </Route>

                        <Route path="/16">
                            <Closing pulse="true" />
                        </Route>

                    </StepWrap>
                </div>
            </MemoryRouter>
        )
    }
}
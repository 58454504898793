import React from 'react';
import { Link } from "react-router-dom";

 
class ToolTipArrow extends React.Component {
  render() {
    return (
        <div className={`tool-tip-arrow ${this.props.toolTipClass}`}>
            <div className="lines">
                <span className="line" />
                <span className="line" />
            </div>
        </div>
    );
  }
}

export class ToolTip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            arrowPulse: false,
            pttClicked: false
        }
    }

    componentDidMount() {
        if(this.props.alertTip) {
            if(this.props.delay) {
                setTimeout(
                    function() {
                        this.setState({ show: true });
                    }
                    .bind(this),
                    this.props.delay * 1000
                );
            }else {
                this.setState({ show: true });
            }
        }else {
            if(this.props.delay) {
                setTimeout(
                    function() {
                        this.setState({ show: true });
                    }
                    .bind(this),
                    this.props.delay * 1000
                );
            }else {
                this.setState({ show: true });
            }
        }

        setTimeout(
            function() {
                this.setState({ arrowPulse: true });
            }
            .bind(this),
            15000
        );
    }

    pastTipClickHandler() {
        this.setState({ pttClicked: !this.state.pttClicked });
    }

    render() {
        let arrow = this.props.arrow ? this.props.arrow : "no";
        let toolTipClass = this.props.className ? this.props.className : "";
        let alertTip = this.props.alertTip ? "alert-tip" : "";

        const uiHeight = 849;
        const uiWidth = 1440;

        const width = this.props.width ? ((this.props.width / uiWidth) * 100) + "%" : "";
        const top = this.props.top ? ((this.props.top / uiHeight) * 100) + "%" : "";
        const left = this.props.left ? ((this.props.left / uiWidth) * 100) + "%" : "";

        const paddingRight = this.props.paddingRight ? this.props.paddingRight + "px" : "42px";
        const style = {
            width: width,
            top: top,
            left: left,
            paddingRight: paddingRight
        }

        const pttClicked = this.state.pttClicked ? "ptt-clicked" : "";

        if(this.props.next){
            return (
              <Link className={`tool-tip ${arrow}-arrow ${toolTipClass} ${alertTip} ${this.state.show ? "show" : ""} ${this.props.show ? "tt-show" : ""} ${pttClicked}`} to={this.props.next} style={style} >
    	      	{this.props.children ? this.props.children : this.props.tip}
    	      	<ToolTipArrow toolTipClass={this.state.arrowPulse ? "pulse" : ""} />                
    	      </Link>
            );
        }else {
            return (
                <div className={`tool-tip stater ${arrow}-arrow ${toolTipClass} ${alertTip} ${this.state.show ? "show" : ""} ${this.props.show ? "tt-show" : ""} ${pttClicked}`} style={style}>
                    {this.props.children ? this.props.children : this.props.tip}
                    <ToolTipArrow toolTipClass={this.state.arrowPulse ? "pulse" : ""} />                  
                </div>
            );
        }
    }
}

export class PastToolTip extends React.Component {

    render() {
        return (
            <div className="past-tool-tip">
                <button className={`past-tool-tip-button`} onClick={this.props.clicked} >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.99955 0.346191C7.28798 0.346191 5.61485 0.853731 4.19173 1.80463C2.76862 2.75552 1.65943 4.10707 1.00444 5.68835C0.349453 7.26964 0.178078 9.00964 0.511988 10.6883C0.845899 12.367 1.6701 13.909 2.88036 15.1192C4.09062 16.3295 5.63259 17.1537 7.31127 17.4876C8.98995 17.8215 10.73 17.6501 12.3112 16.9951C13.8925 16.3402 15.2441 15.231 16.195 13.8079C17.1459 12.3847 17.6534 10.7116 17.6534 9.00004C17.6534 7.8636 17.4296 6.73829 16.9947 5.68835C16.5598 4.63842 15.9223 3.68443 15.1187 2.88084C14.3152 2.07726 13.3612 1.43982 12.3112 1.00492C11.2613 0.570029 10.136 0.346191 8.99955 0.346191ZM8.99955 3.42312C9.20556 3.42312 9.40695 3.48421 9.57824 3.59866C9.74953 3.71312 9.88303 3.8758 9.96187 4.06612C10.0407 4.25645 10.0613 4.46588 10.0211 4.66794C9.98095 4.86999 9.88175 5.05558 9.73608 5.20126C9.59041 5.34693 9.40481 5.44613 9.20276 5.48632C9.00071 5.52651 8.79127 5.50588 8.60095 5.42705C8.41062 5.34821 8.24794 5.2147 8.13349 5.04341C8.01903 4.87212 7.95795 4.67074 7.95795 4.46473C7.95795 4.32794 7.98489 4.19249 8.03723 4.06612C8.08958 3.93975 8.1663 3.82492 8.26302 3.7282C8.35975 3.63148 8.47457 3.55475 8.60095 3.50241C8.72732 3.45006 8.86277 3.42312 8.99955 3.42312ZM11.2535 13.3106C9.86466 15.1045 7.95795 14.7674 7.95795 13.6888V9.32462C7.95795 8.08164 6.86858 8.92969 5.86314 10.2245C5.98416 9.47983 6.28731 8.77664 6.74562 8.17741C8.13444 6.38354 10.0412 6.72061 10.0412 7.79925V12.1634C10.0412 13.4064 11.1305 12.5584 12.136 11.2636C12.015 12.0082 11.7118 12.7114 11.2535 13.3106Z" fill="#F4F4F4"/>
                    </svg>

                    <span className="text">See former tool tip</span>
                </button>
            </div>
        );
    }
}

import React from 'react';
import { BrowserRouter as Router, Route, MemoryRouter } from "react-router-dom";
import Opening from '../components/steps/Opening.js';
import Closing from '../components/steps/Closing.js';
import MissionControl from '../components/steps/MissionControl.js';
import AlertsView from '../components/steps/Alerts.js';
import StepWrap from '../components/steps/StepRoute.js';
import DigitalReport from '../components/steps/DigitalReport.js';
import Login from '../components/steps/Login.js';
import {ToolTip} from '../components/steps/ToolTip.js';
import BackButton from '../components/BackButton.js';

//IMAGES
import StaticImages from '../components/StaticImages.js';
import pdfCover from '../images/PDFReport-Cover.png';
import pdfPg1 from '../images/PDFReport-Interior-Pg1.png';
import pdfPg2 from '../images/PDFReport-Interior-Pg2.png';
import pdfPg3 from '../images/PDFReport-Interior-Pg3.png';
import pdfPg4 from '../images/PDFReport-Interior-Pg4.png';
import pdfPg5 from '../images/PDFReport-Interior-Pg5.png';
import pdfCisco from '../images/DTEX-UC2-Seq5-PDFReport-CISO.jpg';


export default class Steps extends React.Component {

    render() {

        const completionURL = window.location.protocol + "//" + window.location.hostname + "/experiencenow/#request_form";


        console.log(completionURL);

        return (
            <MemoryRouter>
                <div className="steps-wrap">                    
                    <StepWrap>
                        <BackButton />
                        <Route path="/">
                          <Opening />
                        </Route>                        
                        <Route path="/2">
                          <Login next="/3">
                          </Login>
                        </Route>
                        <Route path="/3">
                          <MissionControl />
                        </Route>
                        <Route path="/4">
                          <AlertsView />
                        </Route>
                        <Route path="/5">
                          <DigitalReport />
                        </Route>

                        {/* REPORT COVER */}
                        <Route path="/6">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfCover} />
                          <ToolTip top="69" left="1021" width="342" arrow="left" next="/7" tip="The report summarizes the  activities generated by a user, how many devices the user has been active on and the overall risk rating." delay={1}/>
                        </Route>
                        <Route path="/7">
                          <StaticImages className="pdf-report" backgroundColor="#363636" image1={pdfCover} />
                          <ToolTip top="314" left="1021" width="330" arrow="left" next="/8" tip="The spider plot provides a quick visual summary of how a user skews across Insider Threat behavioral categories." delay={.1}/>
                        </Route>
                        <Route path="/8">
                          <StaticImages className="pdf-report" backgroundColor="#363636" image1={pdfCover} />
                          <ToolTip top="605" left="1021" width="342" next="/9" tip="Finally, each behavioral category is broken out by risk score and highlights the riskiest activities generated for the user." delay={.1}/>
                        </Route>

                        {/* REPORT PAGE 1 */}
                        <Route path="/9">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg1} />
                          <ToolTip top="386" left="1021" width="342" next="/10" tip="Malicious Behaviors Summary Report" delay={1}/>
                        </Route>

                        {/* REPORT PAGE 2 */}
                        <Route path="/10">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg2} />
                          <ToolTip top="386" left="1021" width="342" next="/11" tip="Data Loss Behavior Summary" delay={1}/>
                        </Route>

                        {/* REPORT PAGE 3 */}
                        <Route path="/11">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg3} />
                          <ToolTip top="362" left="1021" width="342" next="/12" tip="The Other Behavioral Summary helps analysts understand intent and complete the audit trail" delay={1}/>
                        </Route>

                        {/* REPORT PAGE 4 */}
                        <Route path="/12">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg4} />
                          <ToolTip top="386" left="1021" width="342" next="/13" tip="Top Applications Summary and Websites visited" delay={1}/>
                        </Route>

                        {/* REPORT PAGE 5 */}
                        <Route path="/13">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfPg5} />
                          <ToolTip top="362" left="1021" width="342" next="/14" tip="MITRE ATT&CK® Framework mapping highlights the attack techniques triggered for the user" delay={1}/>
                        </Route>

                        {/* REPORT PAGE RECS */}
                        <Route path="/14">
                          <StaticImages className="pdf-report fade-in" backgroundColor="#363636" image1={pdfCisco} />
                          <span className="report-darken" />
                          <ToolTip top="362" left="523.84" width="408.16" next="/15" tip="DTEX also automatically generates an executive summary of all behaviors across the organization." delay={1}/>
                        </Route>

                        <Route path="/15">
                          <StaticImages className="pdf-report" backgroundColor="#363636" image1={pdfCisco} />                    
                          <ToolTip top="201" left="1021" width="342" arrow="left" next="/16" tip="Summary of workstations, server and active users" delay={.1}/>
                        </Route>

                        <Route path="/16">
                          <StaticImages className="pdf-report" backgroundColor="#363636" image1={pdfCisco} />                    
                          <ToolTip top="425" left="1021" width="342" arrow="left" next="/17" tip="Insider Threat Summary broken down by category along with details highlighting top risks" delay={.1}/>
                        </Route>

                        <Route path="/17">
                          <StaticImages className="pdf-report" backgroundColor="#363636" image1={pdfCisco} />                    
                          <ToolTip top="625" left="1021" width="342" arrow="left" next="/18" tip="Visual summary showing how the organization skews to each major behavioral category, and a summary of recommendations to reduce risk" delay={.1}/>
                        </Route>

                        <Route path="/18">
                          <Closing />
                        </Route>

                        <Route path="/19" component={() => {
                             window.location.href = completionURL;
                        }}/>                        
                  
                    </StepWrap>
                </div>
            </MemoryRouter>
        );
    }
}
import React from 'react';

export default class StaticImages extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      show: false
    }
  }

  componentDidMount() {
    setTimeout(function(){
      this.setState({show: true});
    }.bind(this), 500);
  }

  render() {
    const noTransitionClass = this.props.noTransition ? " no-transition" : "";
    return (
      <>
      { this.props.backgroundImage && this.props.backgroundImage !== "" ? <div className="dtex-background-image-ui dtex-sequence-box for-static-image"><img src={this.props.backgroundImage} alt="user interface background" /></div> : "" }

      { this.props.backgroundColor && this.props.backgroundColor !== "" ? <div className="dtex-background-color" style={{backgroundColor: this.props.backgroundColor}}></div> : "" }

      <div className={`static-image-wrap${noTransitionClass} ${this.state.show ? "show" : ""} ${this.props.className}`}>
        {this.props.image1 && this.props.image1 !== ""  ? <img src={this.props.image1} className="static-image" alt="static background 1" /> : ""}
        {this.props.image2 && this.props.image2 !== ""  ? <img src={this.props.image2} className="static-image" alt="static background 2" /> : ""}
      </div>
      </>
    );
  }
}
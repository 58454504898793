import React from 'react';
import {ToolTip} from './ToolTip.js';
import FullDigitalReport from '../../images/full-digital-report.png';
import StickySidebar from '../../images/sticky-sidebar.png';


export default class DigitalReport extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          step: 1
      }
  }

  nextStep() {
    this.setState({step: +this.state.step + 1});
  }

  previousStep() {
      this.setState({step: +this.state.step - 1})
    }

  render() {

    // const checkSec2a = this.state.step === 2 || this.state.step === 3;

    return (
      <div className="digital-report">
         <div className="dtex-background-video-ui">
  
          <img className="sticky-sidebar" src={StickySidebar} alt="report sticky sidebar" />      
          <img className={`full-report step-${this.state.step}`} src={FullDigitalReport} alt="report sticky sidebar" />

          <button className={`back-button${this.state.step > 1 ? "" : " hide"} alerts-back-button`} onClick={this.previousStep.bind(this)}>
            <span className="text">Back</span>
            <div className="lines">
                      <span className="line" />
                      <span className="line" />
                  </div>
          </button>
          
          <div onClick={this.nextStep.bind(this)}>

            {this.state.step === 1 &&                                 
              <ToolTip top="550" left="25" width="288" arrow="right" delay={1} className="mid-tip">
                    {`In this case, we have focused in on Roger, including his overall risk score, the behavioral categories he has triggered, including a timeline of when the events occurred, and what behaviors he has engaged in.  In this case, we see Roger has significant behaviors in Malicious Intent and Data Loss.`}
                  </ToolTip>
            }
            {this.state.step === 2 &&                                 
              <ToolTip top="204" left="1095" width="330" arrow="left" delay={1} className="mid-tip">
                  {`The InTERCEPT Kill Chain highlights the behavior areas including Reconnaissance, Circumvention, Aggregation, Obfuscation and Exfiltration.`}
              </ToolTip>
            }
            {this.state.step === 3 &&                                 
              <ToolTip top="470" left="1095" width="330" arrow="left" delay={1} className="mid-tip">
                  {`DTEX also highlights all the techniques that occurred across the MITRE ATT&CK® Framework.`}
              </ToolTip>
            }
            {this.state.step === 4 &&                                 
              <ToolTip top="484" left="1095" width="288" arrow="left" delay={1} className="mid-tip">
                    {`Comprehensive visibility of all Roger's Data Loss behaviors are highlighted by Indicators of Intent.`}
                  </ToolTip>
            }
            {this.state.step === 5 &&                                 
              <ToolTip top="484" left="25" width="288" arrow="right" delay={1}>
                    {`The contextual activity section provides detailed information around user activity within applications and known processes.`}
                  </ToolTip>
            }

            {this.state.step === 6 &&                                 
              <ToolTip top="484" left="25" width="288" arrow="right" delay={1}>
                    {`A full audit of all Roger's local, on-network and off-network activities`}
                  </ToolTip>
            }
          </div>

          {this.state.step === 7 &&                                 
              <ToolTip top="103" left="435" width="290" arrow="top" delay={1} next="/6">
                    {`Download a complete report. `}
                  </ToolTip>
            }

         </div>
      </div>
    );
  }
}
import React from 'react';
import { ToolTip } from './ToolTip.js';
import { Link } from "react-router-dom";
import ForensicsBackground from '../../images/DTEX-UC3-Seq3a-Forensic-Blank.png';
import ThirdPartyBackground from '../../images/DTEX-UC3-Seq3b-ThirdParty-Blank.png';
import Animator from '../animator.js';

export default class ForensicInvestigation extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            step: 1,
            lastToolTip: 0
        }
    }

    nextStep() {
    	const ltt = +this.state.step + 1 > this.state.lastToolTip ? +this.state.step + 1 : this.state.lastToolTip;
    	this.setState({
    		step: +this.state.step + 1,
    		lastToolTip: ltt
    	});
    }

    previousStep() {
    	this.setState({step: +this.state.step - 1})
    }

	render() {
		const fullFI = "Forensic Investigation";
		return (
			<div className="dtex-pulse-forensic-investigation">			
	    		<div className="dtex-background-video-ui" >	
	    			{(this.state.step >= 1 && this.state.step <= 4) &&
	    				<Animator animation="PulseForensics" delay={0} />
	    			}

	    			{this.state.step > 4 &&
	    				<Animator animation="PulseThirdParty" delay={0} />
	    			}

	    			<img src={ForensicsBackground} alt={`${fullFI} Dashboard background`} className={`forensics-bg step-${this.state.step}`} />
	    			<img src={ThirdPartyBackground} alt={`Third Party Dashboard background`} className={`third-party-dash-bg step-${this.state.step}`} />

	    			<button className={`back-button${this.state.step > 1 ? "" : " hide"} mc-back-button`} onClick={this.previousStep.bind(this)}>
						<span className="text">Back</span>
						<div className="lines">
			                <span className="line" />
			                <span className="line" />
			            </div>
					</button>

	    			{/* TOOL TIPS */}			
					<div onClick={this.nextStep.bind(this)}>

						{this.state.step === 1 &&																		
							<ToolTip width="639" top="404" left="425" delay={6}>
			      				{`The PULSE Rogue Applications Dashboard identifies unapproved applications and software as well as shadow IT deployments.`}
			      			</ToolTip>
						}

						{this.state.step === 2 &&																		
							<ToolTip width="450" top="335" left="761" arrow="bottom" delay={1}>
			      				{`You can see the activity volume for each by date and time. `}
			      			</ToolTip>
						}
						{this.state.step === 3 &&																		
							<ToolTip width="528" top="335" left="161" arrow="bottom" delay={1}>
			      				{`You can also view rogue application usage by individual users and 3rd party contractors including amount of time used per day.`}
			      			</ToolTip>
						}

						{this.state.step === 4 &&
							<span className="rogue-usage-border alert-border blinking" />
						}
						
						{this.state.step === 5 &&																		
							<ToolTip width="578" top="404" left="480" arrow="" delay={3}>
			      				{`You can quickly create custom dashboards for use cases such as Third-Party contractors and Consultants.`}
			      			</ToolTip>
						}						
					</div>

					{this.state.step === 6 &&	
						<Link to="/5">				
							<ToolTip width="578" top="500" left="480" arrow="top" delay={1}>
			      				{`In a single dashboard, you can easily evaluate and analyze contractor and consultant engagement including hours online, risky behaviors, intensity of activity and more.`}
			      			</ToolTip>
		      			</Link>
					}
	    		</div>
	    	</div>
		);
	}
}


import React from 'react';
import lottie from 'lottie-web';
import JobSearchUserScreen from './animations/sequence-03a-jobsearch-userscreen-lottie.json';
import ExFiltrationUserScreen from './animations/improved-exfil-user-screen.json';
import GrowingBars from './animations/growing_bars_lottie.json';
import GrowingBlobs from './animations/growing_blobs_lottie.json';
import LoadingCircles from './animations/loading_circles_lottie.json';
import UploadingResume from './animations/resume_lottie.json';
import LoadingBarChart from './animations/loading_bar_chart_lottie.json';
import PurpleGraphs from './animations/control_center_purple_only.json';
import PirateDownload from './animations/pirate_download.json';
import MalwareSpread from './animations/malware_spread.json';
import PulseForensics from './animations/pulse_forensics.json';
import PulseThirdParty from './animations/pulse_third_party.json';


const Animator = (props) => {

    const animationContainer = React.createRef(); 

    const animations = {
      'JobSearchUserScreen': JobSearchUserScreen,
      'ExFiltrationUserScreen': ExFiltrationUserScreen,
      'GrowingBars' : GrowingBars,
      'GrowingBlobs' : GrowingBlobs,
      'LoadingCircles' : LoadingCircles,
      'UploadingResume' : UploadingResume,
      'LoadingBarChart' : LoadingBarChart,
      'PurpleGraphs' : PurpleGraphs,
      'PirateDownload' : PirateDownload,
      'MalwareSpread' : MalwareSpread,
      'PulseForensics' : PulseForensics,
      'PulseThirdParty' : PulseThirdParty
    }

    let animation = animations[props.animation];
    let effectClass = props.effect ? "has-effect" : "";

    const delay = props.delay ? props.delay : 1;
    const autoplay = props.autoplay !== false ? true : false; 

    React.useEffect(() => {
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        animationData: animation,
        renderer: 'svg',
        loop: props.loop ? true : false,
        autoplay: props.autoplay ? true : false
      });

      if(props.speed) {
        anim.setSpeed(props.speed);
      }

      setTimeout(function(){
        if(props.effect) {
          effectClass = effectClass + " play-effect-" + props.effect;
        }    
      }, 100);

      setTimeout(function(){
        if(autoplay){
          anim.play(); 
        }
        
      }, delay * 1000);


      if(props.animationEnds){
        anim.addEventListener('enterFrame', () => {
          let finalFrame = anim.totalFrames
          let currentFrame = Math.trunc(('enterFrame', anim.currentFrame)) + 1;
          if(currentFrame === finalFrame){
            props.animationEnds();
          }
        });
      }

    }, []);

    setTimeout(function(){
      let animwrap = document.querySelector('.' + props.animation);
      if(animwrap) {
        animwrap.classList.add('show');
        if(props.microAnimation) {
          animwrap.classList.add(props.microAnimation);
        }
      }
    }, delay * 1000);

    return (
      <div className={`lottie-animation ${props.animation} ${props.video ? "video" : ""}${props.className ? " " + props.className : ""} ${effectClass}`} style={{zIndex: 9999}}>
        <div ref={animationContainer} />
      </div>
    ); 
}

export default Animator;
import React from 'react';
import IntroLogo from '../../images/DTEX_Logo.png';
import DtexPulseLogo from '../../images/dtex_pulse_logo.png';
import OpeningPan from '../../images/opening-intercept.jpg';
import PulseOpeningPan from '../../images/DTEX-UC3-Seq0-Intro.png';
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Route, MemoryRouter, Redirect } from "react-router-dom";

export default class Opening extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            setupClass: '',
            redirect: false
        }
    }

    componentDidMount() {
        const component = this;
        if (this.state.imageAnimation !== "") {
            setTimeout(function() {
                component.setState({
                    setupClass: 'show-logo'
                });
            }, 4000);
            setTimeout(function() {
                component.setState({
                    setupClass: 'show-logo blackout'
                });
            }, 5000);

            setTimeout(function() {
                component.setState({
                    redirect: true
                });
            }, 7500);
        }
    }

    render() {

        const panImage = this.props.pulse ? PulseOpeningPan : OpeningPan;

        let style = {
            backgroundImage: 'url(' + panImage + ')'
        }

        const next = this.props.nextStep ? this.props.nextStep : "/2";
        const imgSrc = this.props.pulse ? DtexPulseLogo : IntroLogo;
        
        return (
          <div className={`dtex-background-image track-right ${this.state.setupClass}`} style={style} >
            <div className="logo-wrap">
              <img src={imgSrc} alt="DTEX logo" />
              {this.state.redirect === true &&                
                <Redirect to={next} />                
              }
            </div>
          </div>
        );
    }
}
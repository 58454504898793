import React from 'react';
import MissionControlBG from '../../images/Mission-Control-Empty.png';
import Animator from '../animator.js';
import {ToolTip} from './ToolTip.js';
import {RiskiestUsersCounters, ColorBoxCounters} from './Counter.js';
import { Link } from "react-router-dom";

export default class MissionControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            lastToolTip: 0
        }
    }

    nextStep() {
    	const ltt = +this.state.step + 1 > this.state.lastToolTip ? +this.state.step + 1 : this.state.lastToolTip;
    	this.setState({
    		step: +this.state.step + 1,
    		lastToolTip: ltt
    	});
    }

    previousStep() {
    	this.setState({step: +this.state.step - 1})
    }

    changeStep(e) {
    	const tip = parseInt(e.target.dataset.tip);
    	this.setState({step: tip});
    }

    render() {

    	const ptt = this.state.step >= 7 ? "past-tool-tips" : "";

        return (
            <div className="dtex-background-image-ui" >				
				<img src={MissionControlBG} alt="mission control background" />

				<button className={`back-button${this.state.step > 2 ? "" : " hide"} mc-back-button`} onClick={this.previousStep.bind(this)}>
					<span className="text">Back</span>
					<div className="lines">
		                <span className="line" />
		                <span className="line" />
		            </div>
				</button>

				<div className={`mission-control malicious-insider current-step-${this.state.step} ${ptt}`}>
					
					<span className={`darken${this.state.step !== 1 ? " hide" : ""}`} />

					{/* ANIMATIONS */}

					{this.state.step >= 2 &&
						<>
							<Animator animation="GrowingBars" delay={0} />							
						</>
					}

					{this.state.step >= 3 &&
						<ColorBoxCounters />
					}

					{this.state.step >= 4 &&
						<Animator animation="GrowingBlobs" delay={0} />	
					}

					{this.state.step >= 5 &&
						<Animator animation="LoadingBarChart" delay={0} />
					}

					{this.state.step >= 6 &&
						<>
							<Animator animation="LoadingCircles" delay={0} />
							<RiskiestUsersCounters />
						</>
					}

					
					{/* TOOL TIPS */}			
					<div onClick={this.nextStep.bind(this)}>

						{this.state.step === 1 &&																		
							<ToolTip top="376" left="437" width="447" arrow="" delay={1}>
			      				{`The InTERCEPT Control Center Dashboard provides a high-level overview of your insider security risk profile.`}
			      			</ToolTip>
						}

						{this.state.step === 2 &&
							<div>								
								<ToolTip top="160" left="937" width="325" arrow="top" delay={1}>
				      				<b>Summary</b> - coverage across the entire enterprise
				      			</ToolTip>
			      			</div>
						}

						{this.state.step === 3 &&																													
							<ToolTip top="254" left="507" width="425" arrow="top" delay={.5}>
			      				{`A breakdown of the major insider threat categories and alerts being triggered across all endpoints and servers`}
			      			</ToolTip>			      						      		
						}	

						{this.state.step === 4 &&																													
							<ToolTip top="423" left="507" width="425" arrow="top" delay={1}>
			      				{`A visual summary of threats based on user behaviors and activities`}
			      			</ToolTip>		      			
						}
						{this.state.step === 5 &&																													
							<ToolTip top="400" left="507" width="425" arrow="bottom" delay={1}>
			      				{`The alert activity timeline shows when risky behaviors are occurring.`}
			      			</ToolTip>		      			
						}
						{this.state.step === 6 &&																													
							<ToolTip top="703" left="1060" width="254" arrow="left" delay={2}>
			      				{`The Top User Risk Score is highlighted for quick attention and investigation.`}
			      			</ToolTip>		      			
						}
						
					</div>
					

					{this.state.step === 7 &&																													
						<Link className="risky-user-border" to="/4" />   			
					}

					<div className={`grey-blocks drawer-wrapper step-${this.state.step}`}>
						<div className="grey-block-1"></div>
						<div className="grey-block-2"></div>
						<div className="grey-block-3"></div>
						<div className="grey-block-4"></div>
						<div className="grey-block-5"></div>
						<div className="grey-block-6"></div>
					</div>

				</div>
				
			</div>

        );
    }
}
import React from 'react';
import LoginBG from '../../images/login.png';
import {Link} from "react-router-dom";
import {ToolTip} from './ToolTip.js';

class Typer extends React.Component {

  static defaultProps = {
    dataText: []
  }

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      isDeleting: false,
      loopNum: 0,
      typingSpeed: 150
    }
  }

  componentDidMount() {
  	const component = this;
  	const delay = this.props.delay;
  	setTimeout(function(){
  		component.handleType();
  	}, delay);
  }

  handleType = () => {
    const { dataText } = this.props;
    const { loopNum, text, typingSpeed } = this.state;
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    this.setState({
      text: fullText.substring(0, text.length + 1),
      typingSpeed: 150
    });

    setTimeout(this.handleType, typingSpeed);
  };

  render() {    
    return (
      <span className={`typing-field ${this.props.field}`} >{ this.state.text }</span>
    );
    
  }
}

export default class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showBorder: false
        }
    }

    componentDidMount() {
        setTimeout(
            function() {
                this.setState({ showBorder: true });
            }
            .bind(this),
            4000
        );
    }

    render() {
        const username = this.props.username ? this.props.username : "Analyst";
        const welcomeToolTip = this.props.welcome ? this.props.welcome : "Welcome " + username + ", let's explore the InTERCEPT dashboard. Click below to get started.";
        return (
            <div className="dtex-background-image-ui">
      				<img src={LoginBG} alt="login background" />
      				<Link to={this.props.next} className={`login-border${this.state.showBorder ? ' show-border' : ''}`} />
      				<span className={`login-username`}><Typer field="username" dataText={[username]} delay={500} /></span>
      				<span className={`login-password`}><Typer field="password" dataText={["***************"]} delay={`1500`} /></span>
              <ToolTip top="175" left="476" width="488" arrow="" delay={3} next={this.props.next}>
                    {welcomeToolTip}
                  </ToolTip>
      			</div>
        );
    }
}
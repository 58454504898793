import React from 'react';
import IntroLogo from '../../images/DTEX_Logo.png';
import DtexPulseLogo from '../../images/dtex_pulse_logo.png';

export default class Closing extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            redirect: false
        }
    }

    getCompletionURL(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    componentDidMount() {

        setTimeout(
            function() {
                this.setState({ show: true });
            }
            .bind(this),
            1000
        );

        setTimeout(
            function() {
                window.location.href = window.location.protocol + "//" + window.location.hostname + "/experiencenow/#request_demo";
            }.bind(this), 5000);

    }

    render() {
        const imgSrc = this.props.pulse ? DtexPulseLogo : IntroLogo;
        return (
            <div className={`closing ${this.state.show ? "show" : ""}`}>
        <div className="closing-content">
          <img src={imgSrc} alt="DTEX Logo" className="closing-logo"/>
          <div className="features-list"><span>Enterprise Visibility&nbsp;&nbsp;|&nbsp;</span><span>  Behavioral Awareness&nbsp;&nbsp;|&nbsp;</span><span>  Actionable Insight&nbsp;&nbsp;|&nbsp;</span>  <span>Intelligent Protection</span></div>
        </div>
      </div>
        );
    }
}
import React from 'react';
import MissionControlBG from '../../images/Mission-Control-Empty.png';
import Animator from '../animator.js';
import {ToolTip} from './ToolTip.js';
import {RiskiestUsersCounters, ColorBoxCounters, PurpleBoxCounters, RiskiestPurpleUsersCounters} from './Counter.js';
import { Link } from "react-router-dom";

//ANIMATIONS

export default class MissionControlCC extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            lastToolTip: 0
        }
    }

    nextStep() {
    	const ltt = +this.state.step + 1 > this.state.lastToolTip ? +this.state.step + 1 : this.state.lastToolTip;

    	this.setState({
    		step: +this.state.step + 1,
    		lastToolTip: ltt
    	});

    	if(+this.state.step + 1 === 12) {
    		setTimeout(
		        function() {
		            this.setState({step: 13});
		        }
		        .bind(this),
		        7500
		    );
    	}
    }

    previousStep() {
    	this.setState({step: +this.state.step - 1})
    }

    changeStep(e) {
    	const tip = parseInt(e.target.dataset.tip);
    	this.setState({step: tip});
    }

    render() {

    	const ptt = this.state.step >= 6 ? "past-tool-tips" : "";
    	

        return (
            <div className="dtex-background-image-ui" >				
				<img src={MissionControlBG} alt="mission control background" />							
				<button className={`back-button${this.state.step > 2 ? "" : " hide"} mc-back-button`} onClick={this.previousStep.bind(this)}>
					<span className="text">Back</span>
					<div className="lines">
		                <span className="line" />
		                <span className="line" />
		            </div>
				</button>				

				<div className={`mission-control compromised-computer current-step-${this.state.step} ${ptt}`}>
					
					<span className={`darken${this.state.step !== 1 ? " hide" : ""}`} />

					{/* ANIMATIONS */}

					{(this.state.step >= 2) &&
						<Animator animation="GrowingBars" delay={0} />							
						
					}

					{(this.state.step >= 3 && this.state.step <= 6) &&
						<ColorBoxCounters />
					}

					{(this.state.step >= 4 && this.state.step <= 6) &&
						<Animator animation="GrowingBlobs" delay={0} />	
					}

					{(this.state.step >= 5 && this.state.step <= 6) &&
						<Animator animation="LoadingBarChart" delay={0} />
					}

					{(this.state.step >= 6 && this.state.step <= 6) &&
						<>
							<Animator animation="LoadingCircles" delay={0} />
							<RiskiestUsersCounters />
						</>
					}

					{this.state.step >= 7 && 
						<>
							<Animator animation="PurpleGraphs" delay={0} />	
							<PurpleBoxCounters />
							<RiskiestPurpleUsersCounters />				
						</>
					}
					
					{/* TOOL TIPS */}			
					<div onClick={this.nextStep.bind(this)}>

						{this.state.step === 1 &&																		
							<ToolTip top="376" left="550" width="447" arrow="" delay={1}>
			      				{`The InTERCEPT Control Center dashboard provides a high-level overview of your organization’s insider risk profile and areas of priority.`}
			      			</ToolTip>
						}

						{this.state.step === 2 &&
							<div>								
								<ToolTip top="160" left="937" width="325" arrow="top" delay={1}>
				      				{'First, you can see coverage across the entire enterprise.'}
				      			</ToolTip>
			      			</div>
						}

						{this.state.step === 3 &&																													
							<ToolTip top="254" left="507" width="425" arrow="top" delay={.5}>
			      				{`You can also quickly view a breakdown of the major insider threat behavior categories and alerts being triggered across all endpoints and servers.`}
			      			</ToolTip>			      						      		
						}	

						{this.state.step === 4 &&																													
							<ToolTip top="423" left="507" width="425" arrow="top" delay={1}>
			      				{`A visual summary of threats based on user behaviors and activities`}
			      			</ToolTip>		      			
						}
						{this.state.step === 5 &&																													
							<ToolTip top="400" left="507" width="425" arrow="bottom" delay={1}>
			      				{`The alert activity timeline shows when risky behaviors are occurring.`}
			      			</ToolTip>		      			
						}
						{this.state.step === 6 &&			
							<ToolTip top="703" left="1060" width="354" arrow="left" delay={2}>
			      				{`The Riskiest Users are highlighted for quick attention and investigation.`}
			      			</ToolTip>		      			
						}

						
						{this.state.step === 7 && 
							<ToolTip top="176" left="735" width="455" arrow="left" delay={1}>
			      				{`By selecting a category, you can quickly view behaviors of the same type across the organization.`}
			      			</ToolTip>
						}

						{this.state.step === 8 && 
							<ToolTip top="724" left="256" width="438" arrow="left" delay={1}>
			      				{`The Riskiest Users are highlighted for quick attention and investigation.`}
			      			</ToolTip>
						}

						{this.state.step === 9 && 
							<ToolTip top="304" left="801" width="316" arrow="left" delay={1}>
			      				{`You can hover over each dot for a quick description of the compromised behaviors.`}
			      			</ToolTip>
						}

						{this.state.step === 10 && 
							<>
								<span className="execution-powershell-border" />
								<span className="execution-powershell-hover-text">Execution- PowerShell[T1086]</span>
							</>
						}						

					</div>

					{this.state.step === 11 && 
						<Link to="/4">
							<span className="mission-control-alerts-border alert-border blinking" />
							<ToolTip top="110" left="156" width="256" arrow="left" delay={1}>
			      				{`Next, view the Alerts.`}
			      			</ToolTip>
		      			</Link>
					}

					<div className={`grey-blocks drawer-wrapper step-${this.state.step}`}>
						<div className="grey-block-1"></div>
						<div className="grey-block-2"></div>
						<div className="grey-block-3"></div>
						<div className="grey-block-4"></div>
						<div className="grey-block-5"></div>
						<div className="grey-block-6"></div>
					</div>

				</div>
				
			</div>

        );
    }
}
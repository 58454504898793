import React from 'react';
import { useLocation } from "react-router-dom";

const LocationWrap = (props) => {
  
  const location = useLocation();
  let pathClass = location.pathname === "/" ? "step1" : location.pathname.replace('/', '');

  return (
    <div className={`step-wrapper step${pathClass} ${props.arClass}`}>
      {props.children}
    </div>
  )
  
}

 
class StepWrap extends React.Component {

  constructor(props) {
      super(props)
      this.state = {
          arClass: ''
      }
  }

  componentDidMount() {
    const comp = this;
    window.addEventListener('resize', function(event) {
    	let arc;
        if ((window.innerHeight / window.innerWidth) > .6) {
        	arc = "tall";
        }else {
        	arc = "wide";
        }
        comp.setState({arClass: arc});
    });
    window.addEventListener('load', function(event) {
    	let arc;
        if ((window.innerHeight / window.innerWidth) > .6) {
        	arc = "tall";
        }else {
        	arc = "wide";
        }
        comp.setState({arClass: arc});
    });
}

  render() {
    return (
      <div>
   		   <LocationWrap arClass={this.state.arClass}>{this.props.children}</LocationWrap>	
      </div>
    );
  }
}

export default StepWrap;
import React from 'react';

export default class Counter extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            number: this.props.float ? parseFloat(this.props.start).toFixed(2) : this.props.start
        }
    }

    runCounter() {
      const float = this.props.float ? true : false;
    	const counter = this;
    	const start = float ? parseFloat(this.props.start).toFixed(2) : this.props.start;
    	const end = float ? parseFloat(this.props.end).toFixed(2) : this.props.end;
    	const duration = this.props.duration ? this.props.duration : 600;
    	this.setState({number: start});
    	if (start === end) return;
	    let range = end - start;
	    let current = start;
	    let increment = end > start? 1 : -1;
	    let largeIncrement = 222;
      let floatIncrement = this.props.floatIncrement ? this.props.floatIncrement : 2.233;
        
      if(float) {
          increment = floatIncrement;
      } else {
        
  	    if(range > 5000) {
          largeIncrement = 255;
  	    }

        if(range > 500) {
          increment = largeIncrement;
        }
        
      }

	    let stepTime = Math.abs(duration / range);
	    let timer = setInterval(function() {

            if(increment === largeIncrement && end - current < largeIncrement) {
                current = +end - current;
            }else {
                current = +current + increment;
            }

            let counterNumber;

            if(float) {
                counterNumber = parseFloat(current).toFixed(2);
            }else {
                counterNumber = current;
            }
            
            counter.setState({number: counterNumber.toString()});

            if (current >= end) {
                clearInterval(timer);
            }

        }, stepTime);
    }

    componentDidMount() {
        const start = this.props.start >= 0;
        const end = this.props.end >= 0;
        const delay = this.props.delay ? this.props.delay : 0;

        if (start && end) {
        	            
            if(delay){
                const comp = this;
                setTimeout(function(){
                    comp.runCounter();
                }, delay);
            }else {
                this.runCounter();
            }

        } else if (start && !end) {
            this.setState({ number: start });
        } else if (!start && end) {
            this.setState({ number: end });
        }
    }

    render() {
        const appended = this.props.append ? this.props.append : "";
        return (
    			<div className={`counter${" " + this.props.className}`}>
    				{this.state.number + appended}
    			</div>
        );
    }
}

 
export class StatBarCounter extends React.Component {
  render() {
    return (
      <div className="dtex-stat-bar">
          <Counter className="total-detected" start={0} end={8} float={false} delay={250} />
          <Counter className="urgent" start={0} end={4} float={false} delay={250} />
          <Counter className="high" start={0} end={3} float={false} delay={250} />
          <Counter className="medium" start={0} end={1} float={false} delay={250} />
          <Counter className="low" start={0} end={0} float={false} delay={250} />
          <Counter className="malicious" start={0} end={2} float={false} delay={250} />
          <Counter className="negligent" start={0} end={1} float={false} delay={250} />
          <Counter className="compromised" start={0} end={0} float={false} delay={250} />
          <Counter className="data-loss" start={0} end={2} float={false} delay={250} />
          <Counter className="behavior" start={0} end={2} float={false} delay={250} />
      </div>
    );
  }
}

export class StatBarNoCounter extends React.Component {
  render() {
    return (
      <div className="dtex-stat-bar">
          <Counter className="total-detected" start={8} end={8} float={false} delay={250} />
          <Counter className="urgent" start={4} end={4} float={false} delay={250} />
          <Counter className="high" start={3} end={3} float={false} delay={250} />
          <Counter className="medium" start={1} end={1} float={false} delay={250} />
          <Counter className="low" start={0} end={0} float={false} delay={250} />
          <Counter className="malicious" start={2} end={2} float={false} delay={250} />
          <Counter className="negligent" start={1} end={1} float={false} delay={250} />
          <Counter className="compromised" start={0} end={0} float={false} delay={250} />
          <Counter className="data-loss" start={2} end={2} float={false} delay={250} />
          <Counter className="behavior" start={2} end={2} float={false} delay={250} />
      </div>
    );
  }
}

export class StatBarNoCounterCC extends React.Component {
  render() {
    return (
      <div className="dtex-stat-bar compcomp">
          <Counter className="total-detected" start={11} end={11} float={false} delay={250} />
          <Counter className="urgent" start={5} end={5} float={false} delay={250} />
          <Counter className="high" start={4} end={4} float={false} delay={250} />
          <Counter className="medium" start={2} end={2} float={false} delay={250} />
          <Counter className="low" start={0} end={0} float={false} delay={250} />
          <Counter className="malicious" start={0} end={0} float={false} delay={250} />
          <Counter className="negligent" start={0} end={0} float={false} delay={250} />
          <Counter className="compromised" start={11} end={11} float={false} delay={250} />
          <Counter className="data-loss" start={0} end={0} float={false} delay={250} />
          <Counter className="behavior" start={0} end={0} float={false} delay={250} />
      </div>
    );
  }
}

export class StatBarNoCounterAllZeros extends React.Component {
  render() {
    return (
      <div className="dtex-stat-bar">
          <Counter className="total-detected" start={0} end={0} float={false} delay={250} />
          <Counter className="urgent" start={0} end={0} float={false} delay={250} />
          <Counter className="high" start={0} end={0} float={false} delay={250} />
          <Counter className="medium" start={0} end={0} float={false} delay={250} />
          <Counter className="low" start={0} end={0} float={false} delay={250} />
          <Counter className="malicious" start={0} end={0} float={false} delay={250} />
          <Counter className="negligent" start={0} end={0} float={false} delay={250} />
          <Counter className="compromised" start={0} end={0} float={false} delay={250} />
          <Counter className="data-loss" start={0} end={0} float={false} delay={250} />
          <Counter className="behavior" start={0} end={0} float={false} delay={250} />
      </div>
    );
  }
}

export class RiskiestUsersCounters extends React.Component {
  render() {
    return (
      <div className="dtex-riskiest-users">
          <Counter start={0} end={252} float={false} delay={1000} />
          <Counter start={0} end={232} float={false} delay={1000} />
          <Counter start={0} end={207} float={false} delay={1000} />
          <Counter start={0} end={207} float={false} delay={1000} />
          <Counter start={0} end={166} float={false} delay={1000} />
          <Counter start={0} end={130} float={false} delay={1000} />
          <Counter start={0} end={105} float={false} delay={1000} />
          <Counter start={0} end={65} float={false} delay={1000} />
          <Counter start={0} end={60} float={false} delay={1000} />
      </div>
    );
  }
}

export class RiskiestPurpleUsersCounters extends React.Component {
  render() {
    return (
      <div className="dtex-riskiest-users">
          <Counter start={0} end={81} float={false} delay={1000} />
          <Counter start={0} end={81} float={false} delay={1000} />
          <Counter start={0} end={75} float={false} delay={1000} />
          <Counter start={0} end={56} float={false} delay={1000} />
          <Counter start={0} end={50} float={false} delay={1000} />
          <Counter start={0} end={50} float={false} delay={1000} />
          <Counter start={0} end={50} float={false} delay={1000} />
          <Counter start={0} end={50} float={false} delay={1000} />
          <Counter start={0} end={0} float={false} delay={1000} />
      </div>
    );
  }
}

export class ColorBoxCounters extends React.Component {
  render() {
    return (
      <div className="dtex-color-box-coutners">
          <Counter start={0} end={65} float={false} delay={250} />
          <Counter start={0} end={25} float={false} delay={250} />
          <Counter start={0} end={73} float={false} delay={250} />
          <Counter start={0} end={94} float={false} delay={250} />
          <Counter start={0} end={60} float={false} delay={250} />
      </div>
    );
  }
}

export class PurpleBoxCounters extends React.Component {
  render() {
    return (
      <div className="dtex-color-box-coutners">
          <Counter start={0} end={0} float={false} delay={250} />
          <Counter start={0} end={0} float={false} delay={250} />
          <Counter start={73} end={98} float={false} delay={250} />
          <Counter start={0} end={0} float={false} delay={250} />
          <Counter start={0} end={0} float={false} delay={250} />
      </div>
    );
  }
}

export class NodesCounter extends React.Component {
  render() {
    return (
      <div className="dtex-nodes-counters">
          <Counter start={0} end={19323} float={false} delay={250} />
          <Counter start={0} end={3274} float={false} delay={250} />
          <Counter start={0} end={226} float={false} delay={250} />
          <Counter start={0} end={86} float={false} delay={250} />
          <Counter start={0} end={1874} float={false} delay={250} />
      </div>
    );
  }
}
import React from 'react';
import {ToolTip} from './ToolTip.js';
import FullDigitalReport from '../../images/DTEX-UC2-Seq5-Reports-Digital-Full.png';
import StickySidebar from '../../images/StaticSidebar.png';
import ReportPowershell from '../../images/DTEX-UC2-Seq5b-Reports-Digital-Powershell-Full.png';
import ReportFileLineage from '../../images/DTEX-UC2-Seq5c-Reports-Digital-FileLineage-Full.png';
import FileLineageGrab from '../../images/IsolatedFileLineage.png';
import StaticHeader from '../../images/StaticHeaader.png';
import { Link } from "react-router-dom";

export default class DigitalReportCC extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          step: 1
      }
  }

  nextStep() {
    this.setState({step: +this.state.step + 1});
  }

  previousStep() {
      this.setState({step: +this.state.step - 1})
    }

  render() {

    const darkenSteps = [1, 6];

    return (
      <div className="digital-report">
        {this.state.step === 6 && 
          <img className={`digital-report-file-lineage-grab`} src={FileLineageGrab} alt="file lineage screen grab" />
        }

         <div className="dtex-background-video-ui">


          <span className={`darken${!darkenSteps.includes(this.state.step)  ? " hide" : ""}`} />
  
          <img className="sticky-sidebar skinny" src={StickySidebar} alt="report sticky sidebar" />

          {this.state.step <= 3 &&
            <img className={`full-report compcomp step-${this.state.step}`} src={FullDigitalReport} alt="full digital report" />
          }

          {this.state.step >= 7 &&
            <>
              <img className={`full-report compcomp step-${this.state.step}`} src={FullDigitalReport} alt="full digital report" />
              <img className={`static-header`} src={StaticHeader} alt="static header" />
            </>
          }

          {this.state.step === 4 &&
            <img className={`full-report compcomp step-${this.state.step}`} src={ReportPowershell} alt="powershell report" />
          }

          {(this.state.step === 5 || this.state.step === 6) &&
            <img className={`full-report compcomp step-${this.state.step}`} src={ReportFileLineage} alt="file lineage report" />
          }          

          <button className={`back-button${this.state.step > 1 ? "" : " hide"} alerts-back-button`} onClick={this.previousStep.bind(this)}>
            <span className="text">Back</span>
            <div className="lines">
                      <span className="line" />
                      <span className="line" />
                  </div>
          </button>
          
          <div onClick={this.nextStep.bind(this)}>

            {this.state.step === 1 &&                                 
              <ToolTip top="400" left="400" width="600" delay={1} className="mid-tip">
                    {`The User Investigation dashboard quickly summarizes all behaviors based upon risk & audit data for each user.  The report pulls together all violating behavioral activities along with surrounding data to complete a full audit trail.`}
                  </ToolTip>
            }
            {this.state.step === 2 &&                                 
              <ToolTip top="178" left="390" width="864" arrow="top" delay={1} className="mid-tip">
                  {`You can see data from the compromised user & machine including the overall risk score, the categories of their behavior, and a timeline of when the events occurred.`}
              </ToolTip>
            }
            {this.state.step === 3 &&                                 
              <ToolTip top="256" left="748" width="325" arrow="top" delay={1} className="mid-tip">
                  {`Select an alert to see details.`}
              </ToolTip>
            }
            {this.state.step === 4 &&                                 
              <ToolTip top="190" left="981" width="414" arrow="left" delay={1} className="mid-tip">
                    {`Here, you can see the specific PowerShell attack description and details about who, where, and when the same actions have occurred across the organization.`}
                  </ToolTip>
            }
            {this.state.step === 5 &&
              <>                                 
              <ToolTip top="405" left="825" width="462" arrow="left" delay={1}>
                    {`The File Lineage dashboard tracks the malicious file and how it moved and executed across the network, highlighting all the compromised machines across the organization.`}
                  </ToolTip>
                  <span className="file-lineage-cc alert-border blinking" />
              </>
            }

            {this.state.step === 6 &&                                 
              <ToolTip top="150" left="270" width="864" arrow="" delay={1}>
                    {`The File Lineage visualization graph provides an evidentiary quality audit trail of changes and modifications to each file including user access, renaming, copy and paste actions, and movement within and off network.`}
                  </ToolTip>
            }

            {this.state.step === 6 &&                                 
              <ToolTip top="150" left="270" width="864" arrow="" delay={1}>
                    {`The File Lineage visualization graph provides an evidentiary quality audit trail of changes and modifications to each file including user access, renaming, copy and paste actions, and movement within and off network.`}
                  </ToolTip>
            }
            {this.state.step === 7 &&                                 
              <ToolTip top="132" left="1025" width="385" arrow="left" delay={1} >
                    {`The InTERCEPT Kill Chain highlights behavior and intent in the areas of Reconnaissance, Circumvention, Aggregation, Obfuscation, and Exfiltration.`}
                  </ToolTip>
            }
            {this.state.step === 8 &&                                 
              <ToolTip top="342" left="855" width="385" arrow="left" delay={1} >
                    {`DTEX also highlights all the techniques that map to the MITRE ATT&CK®  framework.`}
                  </ToolTip>
            }
          </div>

          {this.state.step === 9 &&
              <Link to="/6">
                <span className="download-report-border-cc alert-border compcomp blinking" />
                <ToolTip top="96" left="331" width="336" arrow="top" delay={.5} >
                    {`Click here to generate report.`}
                </ToolTip>
              </Link>
            }
          

         </div>
      </div>
    );
  }
}
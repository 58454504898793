import React from 'react';
import WOEBackground from '../../images/DTEX-UC3-Seq2-WOEDashboard-Filled.png';
import WOESidebar from '../../images/StaticSidebar-WOE.png';
import WOEHeader from '../../images/StaticHeader-WOE.png';
import {ToolTip} from './ToolTip.js';
import { Link } from "react-router-dom";


export default class WOEDash extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            lastToolTip: 0
        }
    }

    nextStep() {
    	const ltt = +this.state.step + 1 > this.state.lastToolTip ? +this.state.step + 1 : this.state.lastToolTip;
    	this.setState({
    		step: +this.state.step + 1,
    		lastToolTip: ltt
    	});
    }

    previousStep() {
    	this.setState({step: +this.state.step - 1})
    }

    render() {
    	const fullWOE = "Workforce Operational Efficiency";
    	return (
    		<div className="dtex-pulse-woe-dashboard">
    			
	    		<div className="dtex-background-video-ui" >		
					<img src={WOEBackground} alt={`${fullWOE} Dashboard background`} className={`woe-dash-bg step-${this.state.step}`} />
					<img className="sticky-sidebar skinny" src={WOESidebar} alt={`${fullWOE} sticky sidebar`} />
					<img className="woe-sticky-header" src={WOEHeader} alt={`${fullWOE} sticky sidebar`} />

					<button className={`back-button${this.state.step > 1 ? "" : " hide"} mc-back-button`} onClick={this.previousStep.bind(this)}>
						<span className="text">Back</span>
						<div className="lines">
			                <span className="line" />
			                <span className="line" />
			            </div>
					</button>
					<div className={`grey-boxes woe-step-${this.state.step}`}>
						<span /><span /><span /><span /><span /><span /><span />
					</div>

					<div className={`woe-dash dtex-pulse current-step-${this.state.step}`}>
						
						<span className={`darken${this.state.step !== 1 ? " hide" : ""}`} />						

						{/* TOOL TIPS */}			
						<div onClick={this.nextStep.bind(this)}>

							{this.state.step === 1 &&																		
								<ToolTip top="374" left="341" width="737" arrow="" delay={1}>
				      				{`The PULSE Dashboards include actionable operational intelligence regarding Workforce Engagement, Software License Utilization, Shadow IT & Rogue Application Awareness, and more.`}
				      			</ToolTip>
							}

							{this.state.step === 2 &&																		
								<ToolTip top="278" left="564" width="534" arrow="top" delay={1}>
				      				{`The PULSE Performance Heatmap breaks down engagement hours and overall activity rates across the organization, business unit and by job function.`}
				      			</ToolTip>
							}

							{this.state.step === 3 &&																		
								<ToolTip top="743" left="277" width="560" arrow="top" delay={1}>
				      				{`The geo-location map provides a quick view into the work hours and engagement intensity of the workforce.`}
				      			</ToolTip>
							}

							{this.state.step === 4 &&																		
								<ToolTip top="707" left="919" width="492" arrow="top" delay={1}>
				      				{`Each department’s PULSE profile is built  automatically to understand work intensity trends across the organization.`}
				      			</ToolTip>
							}

							{this.state.step === 5 &&																		
								<ToolTip top="362" left="408" width="625" arrow="" delay={1}>
				      				{`The PULSE measurement aims to track work intensity and is typically represented as a percentage of a group’s historical maximum activity rate. Think of this as the equivalent of an ‘average heart rate’ taken over regular intervals of time.`}
				      			</ToolTip>
							}

							{this.state.step === 6 &&																		
								<ToolTip top="139" left="201" width="341" arrow="right" delay={1}>
				      				{`You can see your organization’s workforce engagement and resource utilization level by day.`}
				      			</ToolTip>
							}

							{this.state.step === 7 &&																		
								<ToolTip top="374" left="387" width="667" arrow="" delay={1}>
				      				{`You can also collaborate with peers to review a collection of metrics about your teams’ activity to inform planning decisions and map cross-functional project staffing.`}
				      			</ToolTip>
							}

							{this.state.step === 8 &&																		
								<ToolTip top="386" left="428" width="584" arrow="" delay={1}>
				      				<p>Four distinct zones of activity are measured to determine the breakdown of activity intensity during the workday: maximum, hard, moderate, and light.</p>
									<p>This data can be viewed by region, department and role.</p>
				      			</ToolTip>
							}

							{this.state.step === 9 &&																		
								<ToolTip top="445" left="92" width="520" arrow="right" delay={1}>
				      				{`In the Activity Breakdown section, all activity interactions get synthesized into activity classifications, both work-related (e.g. email, MS Office, IM, video conferencing, SaaS web-apps) and non-work related (e.g. Social Media, video games etc).`}
				      			</ToolTip>
							}

							{this.state.step === 10 &&																		
								<ToolTip top="650" left="630" width="652" arrow="left" delay={1}>
				      				<p>You can easily see the total length of time teams are engaging in work-related types of activity and applications.</p>
									<p>This bar graph lists the time spent utilizing applications and can be analyzed to identify abnormal engagement with rouge applications versus those on the corporate procurement list. ​</p>
				      			</ToolTip>
							}

						</div>
						{/* END OF TOOL TIPS */}

						{this.state.step === 11 &&	

							<Link to="/4">
								<span className="woe-times-border alert-border blinking" />
			      			</Link>
						}

					</div>
				</div>
			</div>
    	);
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import MaliciousInsider from './use-cases/MaliciousInsider.js';
import CompromisedComputer from './use-cases/CompromisedComputer.js';
import DtexPulse from './use-cases/DtexPulse.js';
import reportWebVitals from './reportWebVitals';
import './scss/main.scss';

const walkthrough = document.getElementById('dtex_walkthrough');
const compromisedComputer = document.getElementById('dtex_compromised_computer');
const dtexPulse = document.getElementById('dtex_pulse');

if (walkthrough) {
    ReactDOM.render(
        <React.StrictMode>
            <MaliciousInsider />
        </React.StrictMode>,
        document.getElementById('dtex_walkthrough')
    );
}


if (compromisedComputer) {
    ReactDOM.render(
        <React.StrictMode>
            <CompromisedComputer />
        </React.StrictMode>,
        document.getElementById('dtex_compromised_computer')
    );
}

if (dtexPulse) {
    ReactDOM.render(
        <React.StrictMode>
            <DtexPulse />
        </React.StrictMode>,
        document.getElementById('dtex_pulse')
    );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React from 'react';
import Counter, {StatBarNoCounterCC} from './Counter.js';
import Animator from '../animator.js';
import AlertsEmptyDrawer from '../../images/DTEX-UC2-Seq4b-Alerts-SingleUser-Empty.png';
import AlertsTwoUsers from '../../images/DTEX-UC2-Seq4c-Alerts-MultiUser-Filled.png';
import AlertDrawerAdjuster from '../../images/DrawerBottom.png';
import Alert1 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert1.png';
import Alert2 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert2.png';
import Alert3 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert3.png';
import Alert4 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert4.png';
import Alert5 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert5.png';
import Alert6 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert6.png';
import Alert7 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert7.png';
import Alert8 from '../../images/DTEX-UC2-Seq4b-Alerts-Piece-Alert8.png';
import AlertLockout from '../../images/DTEX-UC2-Seq4b-Alerts-Lockout.png';

import {ToolTip} from './ToolTip.js';
import { Link } from "react-router-dom";


function SingleAlert(props) {

	let [animate, setAnimate] = React.useState();

	let alertImages = { 'Alert1': Alert1, 'Alert2': Alert2, 'Alert3': Alert3, 'Alert4': Alert4, 'Alert5': Alert5, 'Alert6': Alert6, 'Alert7': Alert7, 'Alert8': Alert8 }

	let imageSrc = alertImages["Alert" + props.number];

	setTimeout(function(){
		if(props.bordered) {
			setAnimate(animate = true);
		}else {
			setAnimate(animate = false);
		}
	}, 500);

	return (
		<div className={`dtex-single-alert dtex-single-alert-${props.number} ${props.bordered ? "will-border" : ""} ${animate ? "bordered" : ""} ${props.alertClass ? props.alertClass : ""}`} >
			<img src={imageSrc} alt={`Alert ${props.number}`} />
			<span className={`dtex-alert-dot alert-level-${props.level ? props.level : "none"} ${props.blinking ? "blinking" : ""}`}></span>
			<span className="border" />
		</div>
	);
}

export default class AlertsView extends React.Component {
	
	constructor(props) {
        super(props);
        this.state = {
            step:1
        }
    }

    nextStep() {
    	this.setState({step: +this.state.step + 1});
    }

    previousStep() {
    	this.setState({step: +this.state.step - 1})
    }

    changeStep(e) {
    	const tip = parseInt(e.target.dataset.tip);
    	this.setState({step: tip});
    }

	render() {
		const videoSteps = [2, 12, 14];
		return (
			<div className="dtex-background-image-ui">
				<StatBarNoCounterCC />
				{this.state.step <= 12 &&
					<img src={AlertsEmptyDrawer} alt="Alerts empty" />
				}

				{this.state.step >= 13 &&
					<img src={AlertsTwoUsers} alt="Alerts two users" />
				}				
				{this.state.step >= 1 &&
					<div className="drawer-wrapper">
						<img src={AlertDrawerAdjuster} alt="Alerts drawer bottom" className={`drawer-bottom compromised-computer step-${this.state.step >= 3 ? "show-alerts" : "no-alerts"} step-${this.state.step}`} />
					</div>
				}
				<button className={`back-button${this.state.step > 1 ? "" : " hide"} alerts-back-button`} onClick={this.previousStep.bind(this)}>
					<span className="text">Back</span>
					<div className="lines">
		                <span className="line" />
		                <span className="line" />
		            </div>
				</button>
				<div className={`alerts-view alert-step-${this.state.step}`}>

					{this.state.step === 14 &&
						<img src={AlertLockout} alt="Alerts lockout" className="alerts-lockout" />
					}
					
					<span className={`darken${!videoSteps.includes(this.state.step)  ? " hide" : ""}`} />

					{/* BORDERS ONLY */}

					{this.state.step === 7 &&
						<span className="alert-script-pulling-border compcomp alert-border" />
					}

					{this.state.step === 11 &&
						<>
							<span className="alert-user-score-border compcomp alert-border" />
							<span className="alert-single-score-border compcomp alert-border" />
						</>
					}

					{/* ALERTS */}
					<div className={`flex-alerts compcomp ${this.state.step >= 13 ? "show-past-tool-tips" : ""}`}>
						
						{(this.state.step >= 3 && this.state.step < 12) &&
							<>
								<SingleAlert number="1" bordered={true} alertClass={'flexed'} />
								<SingleAlert number="2" bordered={true} alertClass={'flexed'} />
							</>
						}

						{(this.state.step >= 3 && this.state.step < 13) &&
							<>
								<SingleAlert number="3" bordered={true} alertClass={'flexed'} />
								<SingleAlert number="4" bordered={true} alertClass={'flexed'} />			
								<SingleAlert number="5" bordered={true} alertClass={'flexed'} />			
								<SingleAlert number="6" bordered={true} alertClass={'flexed'} />
								<SingleAlert number="7" bordered={true} alertClass={'flexed'} />			
								<SingleAlert number="8" bordered={true} alertClass={'flexed'} />							
							</>
						}

					</div>

					{/* TOOL TIPS */}
					<div onClick={this.nextStep.bind(this)}>
							
						{this.state.step === 1 &&						
							<ToolTip top="350" left="457" width="535" arrow="" delay={1} show={true}>
			      				<p>The alert dashboard provides near-real time alerts based on severity and behavioral category.</p>
								<p>Watch what happens when an employee downloads a TV show from a pirated website, unaware that it contains malware that goes on to cause damage across the organization.</p>
			      			</ToolTip>
						}

						{this.state.step === 3 &&
							<ToolTip top="606" left="391" width="825" delay={2} className="mid-tip">
				      			{`The suspicious activity by the user and the hidden malware trigger a series of alerts.`}
				      		</ToolTip>
						}

						{this.state.step === 4  &&
							<ToolTip top="510" left="133" width="361" arrow="right" delay={1} className="mid-tip">
				      			{`Access to pirated media website`}
				      		</ToolTip>
						}

						{this.state.step === 5  &&
							<ToolTip top="490" left="133" width="361" arrow="right" delay={1} className="mid-tip">
				      			{`Execution of Powershell command`}
				      		</ToolTip>
						}
						
						{this.state.step === 6  &&
							<ToolTip top="457" left="133" width="361" arrow="right" delay={1} className="mid-tip">
				      			{`Alerts from antivirus software`}
				      		</ToolTip>
						}

						{this.state.step === 7  &&
							<ToolTip top="395" left="133" width="350" arrow="right" delay={1} className="mid-tip">
				      			{`Script pulling system information and network information`}
				      		</ToolTip>
						}

						{this.state.step === 8  &&
							<ToolTip top="293" left="163" width="320" arrow="right" delay={1} className="mid-tip">
				      			{`Suspicious Execution via command line`}
				      		</ToolTip>
						}

						{this.state.step === 9  &&
							<ToolTip top="640" left="502" width="645" arrow="" delay={1} className="mid-tip">
				      			{`Alert stacking combines all related compromised behaviors to provide an analyst with a quick view of the entire event sequence without having to piece events together one by one.`}
				      		</ToolTip>
						}

						{this.state.step === 10  &&
							<span className="alert-border alert-stack-border compcomp blinking" />
						}						

						{this.state.step === 11  &&
							<ToolTip top="645" left="502" width="645" arrow="" delay={1} className="mid-tip">
				      			{`Each type of behavior is automatically assigned a risk score.  Once the accumulated score reaches the risk threshold, the activities are consolidated into a single contextualized alert.`}
				      		</ToolTip>
						}

						{this.state.step === 13  &&
							<ToolTip top="756" left="383" width="839" arrow="" delay={1} className="mid-tip">
				      			{`As malicious software infects other endpoints & servers on the network, the same behaviors begin to trigger alerts, showing which computers are infected.`}
				      		</ToolTip>
						}

						{this.state.step === 14  &&
							<ToolTip top="567" left="412" width="617" arrow="top" delay={1} className="mid-tip">
				      			{`DTEX provides the option to quickly remove compromised or suspicious users off the network to limit its spread of malware and provide time for further investigation and remediation.`}
				      		</ToolTip>
						}						

					</div>

					{this.state.step === 15  &&
							<Link to="/5">
								<span className="roger-comp-border alert-border blinking" />
								<ToolTip top="502" left="398" width="267" arrow="left" delay={1} className="mid-tip">
					      			{`Click here to investigate.`}
					      		</ToolTip>
				      		</Link>
						}

					{/* TOTAL SCORE COUNTER */}
					
					{(this.state.step >= 3 && this.state.step <= 12) &&						
						<Counter className="risk-score compcomp" start={0} end={98.5} delay={250} duration={250} append=".00"/>
					}
				
					{/* USER SCREEN ANIMATIONS */}
					{this.state.step === 2 &&						
						<Animator animation="PirateDownload" effect="genie" loop={false} video={true} animationEnds={this.nextStep.bind(this)} delay={1} />
					}		

					{this.state.step === 12 &&						
						<Animator animation="MalwareSpread" effect="genie" loop={false} video={true} animationEnds={this.nextStep.bind(this)} delay={.5} />
					}								
										
				</div>
			</div>
		);
	}
}
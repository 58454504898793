import React from 'react';
import Counter, {StatBarNoCounter} from './Counter.js';
import Animator from '../animator.js';
import AlertsEmptyDrawer from '../../images/UseCase1-Alerts-EmptyDrawerBKGD.png';
import AlertDrawerAdjuster from '../../images/DrawerBottom.png';
import Alert1 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert1.png';
import Alert2 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert2.png';
import Alert3 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert3.png';
import Alert4 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert4.png';
import Alert5 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert5.png';
import Alert6 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert6.png';
import Alert7 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert7.png';
import Alert8 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert8.png';
import Alert9 from '../../images/DTEX-UC1-Seq3-Alerts-Piece-CROP-Alert9.png';

import {ToolTip} from './ToolTip.js';
import { Link } from "react-router-dom";


function SingleAlert(props) {

	let [animate, setAnimate] = React.useState();

	let alertImages = { 'Alert1': Alert1, 'Alert2': Alert2, 'Alert3': Alert3, 'Alert4': Alert4, 'Alert5': Alert5, 'Alert6': Alert6, 'Alert7': Alert7, 'Alert8': Alert8, 'Alert9': Alert9 }

	let imageSrc = alertImages["Alert" + props.number];

	setTimeout(function(){
		if(props.bordered) {
			setAnimate(animate = true);
		}else {
			setAnimate(animate = false);
		}
	}, 500);

	return (
		<div className={`dtex-single-alert dtex-single-alert-${props.number} ${props.bordered ? "will-border" : ""} ${animate ? "bordered" : ""} ${props.alertClass ? props.alertClass : ""}`} >
			<img src={imageSrc} alt={`Alert ${props.number}`} />
			<span className={`dtex-alert-dot alert-level-${props.level ? props.level : "none"} ${props.blinking ? "blinking" : ""}`}></span>
			<span className="border" />
		</div>
	);
}

export default class AlertsView extends React.Component {
	
	constructor(props) {
        super(props);
        this.state = {
            step: 1
        }
    }

    nextStep() {
    	this.setState({step: +this.state.step + 1});
    }

    previousStep() {
    	this.setState({step: +this.state.step - 1})
    }

    changeStep(e) {
    	const tip = parseInt(e.target.dataset.tip);
    	this.setState({step: tip});
    }

	render() {
		const videoSteps = [1, 2, 4, 6];
		
		return (
			<div className="dtex-background-image-ui">
				<StatBarNoCounter />
				<img src={AlertsEmptyDrawer} alt="Alerts empty" />
				{this.state.step >= 1 &&
					<div className="drawer-wrapper">
						<img src={AlertDrawerAdjuster} alt="Alerts drawer bottom" className={`drawer-bottom step-${this.state.step}`} />
					</div>
				}
				<button className={`back-button${this.state.step > 1 ? "" : " hide"} alerts-back-button`} onClick={this.previousStep.bind(this)}>
					<span className="text">Back</span>
					<div className="lines">
		                <span className="line" />
		                <span className="line" />
		            </div>
				</button>
				<div className={`alerts-view alert-step-${this.state.step}`}>
					
					<span className={`darken${!videoSteps.includes(this.state.step)  ? " hide" : ""}`} />

					{/* BORDERS ONLY */}
					{this.state.step === 12 &&
						<span className="alert-stack-border alert-border" />
					}

					{this.state.step === 13 &&
						<>
							<span className="alert-user-score-border alert-border" />
							<span className="alert-single-score-border alert-border" />
						</>
					}

					{this.state.step === 14 &&
						<Link className="alert-computer-link alert-border" to="/5" />
					}

					{/* ALERTS */}
					<div className={`flex-alerts ${this.state.step >= 14 ? "show-past-tool-tips" : ""}`}>
						
						{this.state.step >= 3 &&
							<SingleAlert number="1" bordered={true} alertClass={'flexed'} />
						}

						{this.state.step >= 5 &&
							<SingleAlert number="2" bordered={true} alertClass={'flexed'} />
						}

						{this.state.step >= 7 &&
							<SingleAlert number="3" bordered={true} alertClass={'flexed'} />
						}

						{this.state.step >= 8 &&
							<SingleAlert number="4" bordered={true} alertClass={'flexed'} />							
						}

						{this.state.step >= 8 &&
							<SingleAlert number="5" bordered={true} alertClass={'flexed'} />							
						}

						{this.state.step >= 9 &&
							<SingleAlert number="6" bordered={true} alertClass={'flexed'} />							
						}

						{this.state.step >= 10 &&
							<SingleAlert number="7" bordered={true} alertClass={'flexed'} />							
						}

						{this.state.step >= 11 &&
							<SingleAlert number="8" bordered={true} alertClass={'flexed'} />
						}
						{this.state.step >= 11 &&
							<SingleAlert number="9" bordered={true} alertClass={'flexed'} />
						}
					</div>

					{/* TOOL TIPS */}
					<div onClick={this.nextStep.bind(this)}>
							
						{this.state.step === 1 &&						
							<ToolTip top="352" left="366" width="709" arrow="" delay={1} show={true}>
			      				<p>The alert dashboard provides near real-time alerts based on severity and behavioral category.</p>
			      				<p>Watch what happens when an employee searches job sites.</p>
			      			</ToolTip>
						}

						{this.state.step === 3 &&
							<ToolTip top="258" left="235" width="263.13" arrow="right" delay={1} className="mid-tip">
				      			{`Because this is atypical behavior for the user, a flight risk alert is triggered.`}
				      		</ToolTip>
						}

						{this.state.step === 5 &&
							<ToolTip top="258" left="140" width="357" arrow="right" delay={1} className="mid-tip">
				      			<p>Later, a resume being uploaded triggers another alert.</p>
				      			<p>The user’s abnormal behaviors continue.</p>
				      		</ToolTip>
						}
						
						{this.state.step === 7 &&
							<ToolTip top="258" left="133" width="364" arrow="right" paddingRight="55" delay={1} className="mid-tip">
				      			{`Each user action is captured as a corresponding contextual alert. Here you can see sensitive company data was downloaded`}
				      		</ToolTip>
						}

						{this.state.step === 8 &&
							<ToolTip top="258" left="240" width="257" arrow="right" delay={1} className="mid-tip">
				      			{`Sensitive files were renamed, archived and encrypted.`}
				      		</ToolTip>
						}

						{this.state.step === 9 &&
							<ToolTip top="258" left="240" width="257" arrow="right" paddingRight="55" delay={1} className="mid-tip">
				      			{`Non-company compliant webmail was used.`}
				      		</ToolTip>
						}

						{this.state.step === 10 &&
							<ToolTip top="258" left="240" width="257" arrow="right" paddingRight="66" delay={1} className="mid-tip">
				      			{`Data was exfiltrated via webmail and a file-sharing site.`}
				      		</ToolTip>
						}

						{this.state.step === 11 &&
							<ToolTip top="258" left="240" width="257" arrow="right" delay={1} show={true} className="mid-tip">
				      			{`Files were deleted.`}
				      		</ToolTip>
						}

						{this.state.step === 12 &&
							<ToolTip top="649" left="500" width="605" arrow="top" delay={1} show={true}>
				      			{`Alert stacking combines all related risky behaviors to provide an analyst with a quick view of the entire event sequence without having to piece events together one by one.`}
				      		</ToolTip>
						}

						{this.state.step === 13 &&
							<ToolTip top="649" left="533" width="539" delay={1} show={true}>
				      			{`Each type of behavior is automatically assigned a risk score.  Once the accumulated score reaches the risk threshold, the activities are consolidated into a single contextualized alert.`}
				      		</ToolTip>
						}

					</div>

					{/* TOTAL SCORE COUNTER */}
					
					{this.state.step === 3 &&						
						<Counter className="risk-score" start={0.00} end={5.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}
					{this.state.step === 4 &&						
						<Counter className="risk-score" start={5.00} end={5.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}

					{this.state.step === 5 &&						
						<Counter className="risk-score" start={5.00} end={17.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}

					{this.state.step === 6 &&						
						<Counter className="risk-score" start={17.00} end={17.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}

					{this.state.step === 7 &&						
						<Counter className="risk-score" start={17.00} end={84.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}
					{this.state.step === 8 &&						
						<Counter className="risk-score" start={84.00} end={96.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}
					{this.state.step === 9 &&						
						<Counter className="risk-score" start={96.00} end={96.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}
					{this.state.step === 10 &&						
						<Counter className="risk-score" start={96.00} end={126.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}
					{this.state.step === 11 &&						
						<Counter className="risk-score" start={126.00} end={177.00} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}
					{this.state.step === 12 &&						
						<Counter className="risk-score" start={177.10} end={177.10} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}
					{this.state.step === 13 &&						
						<Counter className="risk-score" start={177.10} end={177.10} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}

					{this.state.step === 14 &&						
						<Counter className="risk-score" start={177.10} end={177.10} float={true} delay={250} duration={250} floatIncrement={0.233333} />
					}


					{/* USER SCREEN ANIMATIONS */}

					{this.state.step === 2 &&						
						<Animator animation="JobSearchUserScreen" effect="genie" loop={false} video={true} animationEnds={this.nextStep.bind(this)} delay={.5} />
					}

					{this.state.step === 4 &&						
						<Animator animation="UploadingResume" effect="genie" loop={false} video={true} animationEnds={this.nextStep.bind(this)} delay={.5} />
					}

					{this.state.step === 6 &&						
						<Animator animation="ExFiltrationUserScreen" effect="genie" loop={false} video={true} animationEnds={this.nextStep.bind(this)} delay={.5} />
					}											
										
				</div>
			</div>
		);
	}
}